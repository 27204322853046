<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "f-easy",
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
};
</script>

<style lang="scss">
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/roboto-v30-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/roboto-v30-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/roboto-v30-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/roboto-v30-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/roboto-v30-latin-regular.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  color: #31414f;
}

p {
  margin: 0;
  padding: 0;
}

div,
p {
  box-sizing: border-box;
}

#app {
  padding: 0;
  margin: 0;
}

.blue {
  color: #31414f;
}
.green {
  color: #389e8c;
}

.bg-blue {
  background-color: #31414f;
}
.bg-green {
  background-color: #389e8c;
}

.button {
  border-radius: 15px;
  background: #389e8c;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  box-shadow: 4px 4px 8px #00000070;

  &:hover {
    cursor: pointer;
  }
}

.header {
  background: #31414f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;

  img {
    max-width: 40%;
  }

  p {
    font-size: 40px;
    color: #ffffff;
    margin-left: 5%;
    font-style: italic;
  }
}
</style>

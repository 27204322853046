<template>
<div class="home">
  <div class="header">
    <img src="@/assets/logo-claim-neg.png" class="logo" alt="f-easy logo" />
    <img src="@/assets/logo-claim-vert-neg.png" class="logo responsive" alt="f-easy logo" />
    <p>Ganz einfach deinen Führerschein bestehen!</p>
  </div>
  <div class="main">
    <h1>Hoppala - 404</h1>
    <p>Die Seite konnte nicht gefunden werden.</p>
  </div>
  <div class="footer">
    <div class="nav">
      <ul>
        <li>
        <router-link class="blue" to="datenschutz">Datenschutz</router-link>
        </li>
        <li>
        <router-link class="blue" to="impressum">Impressum</router-link>
        </li>
      </ul>
    </div>
    <div>
      <p class="support-text">Mit Unterstützung von Bund, Land und Europäischer Union</p>
      <div class="logos">
        <a href="https://www.bmnt.gv.at/"><img src="@/assets/bund-text.png" alt="mit Unterstützung von Bund, Land und Europäischer Union" /></a>
        <a href="https://ec.europa.eu/agriculture/rural-development-2014-2020_de"><img src="@/assets/le14-20-logo.png" alt="mit Unterstützung von Bund, Land und Europäischer Union" /></a>
        <img src="@/assets/vlbg-logo.png" alt="Logo Vorarlberg" />
        <img src="@/assets/eu-logo.png" alt="Logo EU" />
        <a href="https://www.capito.eu/team/capito-vorarlberg-und-liechtenstein/"><img src="@/assets/capito-logo.png" alt="capito logo" /></a>
        <a href="https://www.dafuer.at/de"><img src="@/assets/dafur-logo.png" alt="dafür logo" /></a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
.home {
  .main {
    width: 75%;
    margin: 50px auto;
  }
}
</style>

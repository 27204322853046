import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import NotFound from "./views/NotFound.vue";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/impressum",
      name: "imprint",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Imprint.vue")
    },
    {
      path: "/datenschutz",
      name: "privacy",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Privacy.vue")
    },
    {
      path: "*",
      name: "not-found",
      component: NotFound
    },
  ]
});

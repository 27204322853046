<template>
  <div class="home">
    <link href="/animate.css" rel="stylesheet" type="text/css" />
    <div class="header">
      <img src="@/assets/logo-claim-neg.png" class="logo" alt="f-easy logo" />
      <img
        src="@/assets/logo-claim-vert-neg.png"
        class="logo responsive"
        alt="f-easy logo"
      />
      <p>Ganz einfach deinen Führerschein bestehen!</p>
    </div>
    <div class="hero-1">
      <!--<img src="@/assets/hero-1.jpeg" alt="heads with question marks" />-->
      <img
        sizes="(max-width: 2600px) 100vw, 2600px"
        srcset="
          @/assets/hero-1_u3ny39_c_scale-w_450.jpg   450w,
          @/assets/hero-1_u3ny39_c_scale-w_629.jpg   629w,
          @/assets/hero-1_u3ny39_c_scale-w_771.jpg   771w,
          @/assets/hero-1_u3ny39_c_scale-w_902.jpg   902w,
          @/assets/hero-1_u3ny39_c_scale-w_1013.jpg 1013w,
          @/assets/hero-1_u3ny39_c_scale-w_1116.jpg 1116w,
          @/assets/hero-1_u3ny39_c_scale-w_1212.jpg 1212w,
          @/assets/hero-1_u3ny39_c_scale-w_1302.jpg 1302w,
          @/assets/hero-1_u3ny39_c_scale-w_1378.jpg 1378w,
          @/assets/hero-1_u3ny39_c_scale-w_1455.jpg 1455w,
          @/assets/hero-1_u3ny39_c_scale-w_1534.jpg 1534w,
          @/assets/hero-1_u3ny39_c_scale-w_1607.jpg 1607w,
          @/assets/hero-1_u3ny39_c_scale-w_1681.jpg 1681w,
          @/assets/hero-1_u3ny39_c_scale-w_1753.jpg 1753w,
          @/assets/hero-1_u3ny39_c_scale-w_1818.jpg 1818w,
          @/assets/hero-1_u3ny39_c_scale-w_1880.jpg 1880w,
          @/assets/hero-1_u3ny39_c_scale-w_1952.jpg 1952w,
          @/assets/hero-1_u3ny39_c_scale-w_2011.jpg 2011w,
          @/assets/hero-1_u3ny39_c_scale-w_2019.jpg 2019w,
          @/assets/hero-1_u3ny39_c_scale-w_2600.jpg 2600w
        "
        src="@/assets/hero-1_u3ny39_c_scale-w_2600.jpg"
        alt="heads with question marks"
      />
    </div>
    <div class="call-to-action">
      <div class="col-1">
        <img src="@/assets/icon.png" class="icon" alt="icon" />
        <p>F-EASY</p>
      </div>
      <div class="col-2">
        <p class="green">
          Hol dir die APP F-EASY und lerne schnell und EASY auf deinen
          Führerschein!
        </p>
        <p>
          Mit unseren Fragen und Erklärungen in leicht verständlicher Sprache.
        </p>
        <a
          href="https://app.f-easy.at"
          class="button"
          target="_blank"
          rel="noopener"
          >Jetzt APP herunterladen</a
        >
      </div>
    </div>
    <div class="advantages bg-green">
      <h1 class="blue">Deine F-EASY-Vorteile</h1>
      <div class="advantage-list">
        <div class="item">
          Original* B-Führerschein-Fragen sowie die Fragen in einer leichter
          verständlichen Sprache
        </div>
        <div class="item">
          Leicht verständliche Erklärungen zu speziellen Begriffen (z.B.: "auf
          halbe Sicht fahren")
        </div>
        <div class="item">
          Lerne gezielt die 300 schwierigsten Führerscheinfragen
        </div>
        <div class="item">
          Animierte Bilder zeigen dir, auf was du achten musst
        </div>
        <div class="item">
          Lerne nach Themen oder trainiere mit allen Fragen
        </div>
        <div class="item">
          Übe mit dem Prüfungssimulator die Führerscheinprüfung
        </div>
        <div class="item">
          Profitiere vom extra Training für deine falsch beantworteten Fragen
        </div>
        <div class="item">
          Sammle 'richtig beantwortete Fragen' und "pimp your car"
        </div>
        <div class="item">
          Du bekommst Auszeichnungen für abgeschlossene Lerneinheiten
        </div>
        <div class="item">
          Teile deine Lern-Statisktik auf Facebook und Instagram mit deinen
          Freunden
        </div>
        <div class="footnote">
          *Bei den verwendeten Fragen handelt es sich um die offiziellen Fragen
          des österreichischen Verkehrsministeriums
        </div>
      </div>
    </div>
    <div class="header">
      <img src="@/assets/logo-claim-neg.png" class="logo" alt="f-easy logo" />
      <img
        src="@/assets/logo-claim-vert-neg.png"
        class="logo responsive"
        alt="f-easy logo"
      />
      <p>
        APP laden, registrieren,<br />
        und ab geht die Fahrt ...
      </p>
    </div>
    <div class="call-to-action preview">
      <div class="col-1">
        <img src="@/assets/preview-image.png" alt="preview image" />
      </div>
      <div class="col-2">
        <p>
          Nutze Erklärungen und Fragen in leicht verständlicher Sprache und
          lerne leichter.
        </p>
        <a
          href="https://app.f-easy.at"
          class="button"
          target="_blank"
          rel="noopener"
          >Jetzt APP herunterladen</a
        >
      </div>
    </div>
    <div class="social-media bg-green blue">
      <p>
        Teile deine Erfolge mit einem Klick mit <br />deinen Freunden! F-Easy
        macht's möglich.
      </p>
      <div class="icons">
        <img
          src="@/assets/social-media-icons.png"
          alt="facebook, instagram, whatsapp"
        />
      </div>
    </div>
    <div class="slider">
      <div class="slider-control back" @click="prevQuote">
        <img src="@/assets/arrow-prev.png" alt="back" />
      </div>
      <div class="slider-items blue">
        <template v-for="(item, index) in quotes">
          <transition
            name="quote-transition"
            enter-active-class="animated lightSpeedIn"
            leave-active-class="animated bounceOutRight"
            mode="out-in"
          >
            <div v-if="index == curItem" class="slider-item" :key="item.name">
              <img
                src="@/assets/quote-start.png"
                alt="zitat start"
                class="quote start"
              />
              <p class="text">{{ item.text }}</p>
              <p class="name">{{ item.name }}</p>
              <img
                src="@/assets/quote-end.png"
                alt="zitat end"
                class="quote end"
              />
            </div>
          </transition>
        </template>
      </div>
      <div class="slider-control next" @click="nextQuote">
        <img src="@/assets/arrow-next.png" alt="next" />
      </div>
    </div>
    <div class="header">
      <img src="@/assets/logo-claim-neg.png" class="logo" alt="f-easy logo" />
      <img
        src="@/assets/logo-claim-vert-neg.png"
        class="logo responsive"
        alt="f-easy logo"
      />
      <p>
        Hol dir F-EASY und du hast deinen<br />Führerschein - schnell und EASY!
      </p>
    </div>
    <div class="hero-1">
      <!--<img src="@/assets/hero-2.jpeg" alt="people on phones" />-->
      <img
        sizes="(max-width: 2600px) 100vw, 2600px"
        srcset="
          @/assets/hero-2_woqpvh_c_scale-w_450.jpg   450w,
          @/assets/hero-2_woqpvh_c_scale-w_610.jpg   610w,
          @/assets/hero-2_woqpvh_c_scale-w_740.jpg   740w,
          @/assets/hero-2_woqpvh_c_scale-w_853.jpg   853w,
          @/assets/hero-2_woqpvh_c_scale-w_962.jpg   962w,
          @/assets/hero-2_woqpvh_c_scale-w_1059.jpg 1059w,
          @/assets/hero-2_woqpvh_c_scale-w_1149.jpg 1149w,
          @/assets/hero-2_woqpvh_c_scale-w_1232.jpg 1232w,
          @/assets/hero-2_woqpvh_c_scale-w_1312.jpg 1312w,
          @/assets/hero-2_woqpvh_c_scale-w_1388.jpg 1388w,
          @/assets/hero-2_woqpvh_c_scale-w_1461.jpg 1461w,
          @/assets/hero-2_woqpvh_c_scale-w_1532.jpg 1532w,
          @/assets/hero-2_woqpvh_c_scale-w_1600.jpg 1600w,
          @/assets/hero-2_woqpvh_c_scale-w_1674.jpg 1674w,
          @/assets/hero-2_woqpvh_c_scale-w_1737.jpg 1737w,
          @/assets/hero-2_woqpvh_c_scale-w_1802.jpg 1802w,
          @/assets/hero-2_woqpvh_c_scale-w_1863.jpg 1863w,
          @/assets/hero-2_woqpvh_c_scale-w_2600.jpg 2600w
        "
        src="@/assets/hero-2_woqpvh_c_scale-w_2600.jpg"
        alt="people on phones"
      />
    </div>
    <div class="footer">
      <div class="nav">
        <ul>
          <li>
            <router-link class="blue" to="datenschutz">Datenschutz</router-link>
          </li>
          <li>
            <router-link class="blue" to="impressum">Impressum</router-link>
          </li>
        </ul>
      </div>
      <div>
        <p class="support-text">
          Mit Unterstützung von Bund, Land und Europäischer Union
        </p>
        <div class="logos">
          <a href="https://www.bmnt.gv.at/"
            ><img
              src="@/assets/bund-text.png"
              alt="mit Unterstützung von Bund, Land und Europäischer Union"
          /></a>
          <a
            href="https://ec.europa.eu/agriculture/rural-development-2014-2020_de"
            ><img
              src="@/assets/le14-20-logo.png"
              alt="mit Unterstützung von Bund, Land und Europäischer Union"
          /></a>
          <a><img src="@/assets/vlbg-logo.png" alt="Logo Vorarlberg"/></a>
          <a><img src="@/assets/eu-logo.png" alt="Logo EU"/></a>
          <a
            href="https://www.capito.eu/team/capito-vorarlberg-und-liechtenstein/"
            ><img src="@/assets/capito-logo.png" alt="capito logo"
          /></a>
          <a href="https://www.dafuer.at/de"
            ><img src="@/assets/dafur-logo.png" alt="dafür logo"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      quotes: [
        {
          text:
            "Mit F-EASY hab ich es nun endlich geschafft. Ich hab meinen Führerschein und kann jetzt fahren wohin ich will.",
          name: "Patrick",
        },
        {
          text:
            "Ich finde die zusätzlichen Erklärungen super. Die haben mir oft beim Lernen geholfen.",
          name: "Milena",
        },
        {
          text:
            "Die F-EASY App ist cool. Meine Freunde und ich haben uns gegenseitig gepusht mit dem Teilen unserer Tageserfolge. Jeder wollte zuerst sein Auto pimpen. Das Lernen lief fast nebenbei :)",
          name: "Martin",
        },
        {
          text:
            'Ich habe die "schweren Fragen" und "meine falsch beantworteten Fragen" sehr intensiv gelernt. Diese Kategorien fand ich super.',
          name: "Ali",
        },
      ],
      curItem: 0,
    };
  },
  mounted() {},
  methods: {
    nextQuote() {
      let max = this.quotes.length - 1;
      let next = this.curItem + 1;
      if (next > max) next = 0;

      this.curItem = next;
    },
    prevQuote() {
      let next = this.curItem - 1;
      if (next < 0) next = this.quotes.length - 1;

      this.curItem = next;
    },
  },
};
</script>

<style lang="scss">
.home {
  img {
    max-width: 100%;
    height: auto;
  }

  .header {
    background: #31414f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0;

    @media all and (max-width: 450px) {
      padding: 30px 10px;
    }

    img {
      max-width: 40%;

      &.logo.responsive {
        display: none;
      }

      @media all and (max-width: 550px) {
        &.logo {
          display: none;
          &.responsive {
            display: block;
          }
        }
      }

      @media all and (max-width: 450px) {
        max-width: 50%;
      }
    }

    p {
      font-size: 40px;
      color: #ffffff;
      margin-left: 5%;
      font-style: italic;
      @media all and (max-width: 1100px) {
        font-size: 30px;
      }
      @media all and (max-width: 830px) {
        font-size: 20px;
      }
      @media all and (max-width: 450px) {
        font-size: 16px;
      }
      @media all and (max-width: 320px) {
        font-size: 13px;
      }
    }
  }

  .hero-1 {
    overflow: hidden;
    img {
      margin-top: -100px;
      width: 100%;
      height: auto;
      @media all and (max-width: 450px) {
        margin-top: 0;
      }
    }
  }

  .call-to-action {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 100px 0;
    @media all and (max-width: 550px) {
      padding: 40px 0;
      flex-direction: column;
      align-items: center;
    }

    p {
      font-size: 25px;
      @media all and (max-width: 550px) {
        font-size: 18px;
        text-align: center;
      }
      @media all and (max-width: 320px) {
        font-size: 15px;
      }
    }

    .col-1 {
      display: flex;
      flex-direction: column;
      margin-right: 5%;
      max-width: 10%;
      min-width: 200px;
      @media all and (max-width: 550px) {
        min-width: 30%;
      }

      .icon {
        max-width: 100%;
      }

      p {
        text-align: center;
      }
    }

    .col-2 {
      display: flex;
      flex-direction: column;
      max-width: 21%;
      min-width: 400px;

      @media all and (max-width: 550px) {
        margin-top: 40px;
        min-width: 70%;
      }

      .button {
        margin-top: 20px;
        align-self: flex-start;
        font-size: 30px;
        padding: 25px 30px;
        @media all and (max-width: 550px) {
          font-size: 18px;
          margin: 20px auto 0 auto;
        }
        @media all and (max-width: 320px) {
          font-size: 16px;
        }
      }
    }

    &.preview {
      align-items: center;
      .col-1 {
        max-width: 40%;
      }

      .col-2 {
        p {
          font-size: 40px;
          @media all and (max-width: 830px) {
            font-size: 30px;
          }
          @media all and (max-width: 320px) {
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }

  .advantages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      width: calc(85% - 70px - 70px); // item padding + icon width
      margin: 50px auto 80px auto;
      font-weight: normal;
      font-size: 40px;
      @media all and (max-width: 450px) {
        font-size: 30px;
      }
      @media all and (max-width: 320px) {
        font-size: 24px;
        width: 100%;
        text-align: center;
        margin: 50px auto 50px;
      }
    }

    .advantage-list {
      display: flex;
      flex-wrap: wrap;
      align-items: space-between;
      width: 85%;

      .footnote {
        color: #fff;
        margin-left: auto;
        font-size: 20px;
        max-width: 30%;
        padding-top: 30px;

        @media all and (max-width: 1200px) {
          max-width: 100%;
          margin-bottom: 40px;
          padding-top: 0;
        }
        @media all and (max-width: 320px) {
          font-size: 16px;
        }
      }

      .item {
        flex-basis: 32%;
        position: relative;
        min-height: 100px;
        color: #fff;
        font-size: 20px;
        padding-left: 70px;
        margin-top: 30px;
        margin-bottom: 80px;

        @media all and (max-width: 320px) {
          min-height: auto;
        }

        &:nth-child(3n - 1) {
          margin: 30px 20px;
          @media all and (max-width: 1200px) {
            margin: 30px 0 80px 0;
          }
          @media all and (max-width: 750px) {
            margin: 0;
          }
          @media all and (max-width: 450px) {
            margin: 0;
          }
          @media all and (max-width: 320px) {
            margin: 0 0 15px 0;
          }
        }

        &:before {
          position: absolute;
          top: -15px;
          left: 0;
          content: "";
          width: 60px;
          height: 60px;
          background: url("~@/assets/arrow.png");
          background-repeat: no-repeat;
          background-size: cover;
          @media all and (max-width: 450px) {
            transform: scale(0.8);
          }
          @media all and (max-width: 320px) {
            transform: scale(0.6);
          }
        }

        @media all and (max-width: 1200px) {
          flex-basis: 45%;
          &:nth-child(2n) {
            margin-left: 50px;
          }
        }

        @media all and (max-width: 750px) {
          flex-basis: 100%;
          &:nth-child(2n) {
            margin: 0;
          }
          margin: 0;
        }

        @media all and (max-width: 450px) {
          margin-bottom: 50px;
          &:nth-child(2n) {
            margin-bottom: 50px;
          }
        }

        @media all and (max-width: 320px) {
          font-size: 16px;
          margin-bottom: 15px;

          &:nth-child(2n) {
            margin-bottom: 15px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .social-media {
    display: flex;
    justify-content: center;
    font-size: 50px;
    padding: 50px 100px;

    @media all and (max-width: 1490px) {
      br {
        display: none;
      }
    }

    @media all and (max-width: 1100px) {
      font-size: 30px;
    }

    @media all and (max-width: 550px) {
      flex-direction: column;
      padding: 50px 40px;
      text-align: center;
      align-items: center;
      .icons {
        margin-top: 50px;
        max-width: 80%;
        text-align: center;
      }
    }

    @media all and (max-width: 320px) {
      font-size: 18px;
    }

    .icons {
      margin-left: 5%;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .slider {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    padding: 20vh 0;
    width: 75%;
    margin: 0 auto;
    overflow: hidden;

    @media all and (max-width: 320px) {
      width: 99%;
    }

    .slider-control {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .slider-items {
      width: 90%;
      position: relative;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      @media all and (max-width: 320px) {
        width: 99%;
      }

      .slider-item {
        font-size: 40px;
        width: 50%;
        height: 100%;
        padding: 0 4%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: absolute;

        @media all and (max-width: 1200px) {
          width: 80%;
        }

        @media all and (max-width: 1100px) {
          font-size: 30px;
        }
        @media all and (max-width: 830px) {
          font-size: 20px;
        }

        @media all and (max-width: 320px) {
          font-size: 16px;
          width: 95%;
        }

        .name {
          margin-top: 20px;
        }

        .quote {
          position: absolute;
          @media all and (max-width: 830px) {
            transform: scale(0.5);
          }

          &.start {
            bottom: 0;
            left: 0;
          }
          &.end {
            top: 0;
            right: 0;
          }
        }

        &:before,
        &:after {
          font-size: 100px;
          line-height: 0;
        }

        &:before {
          bottom: 0;
          left: 0;
        }

        &:after {
          top: 0;
          right: 0;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;

    @media all and (max-width: 550px) {
      flex-direction: column;
      padding-left: 20px;
    }

    .nav {
      margin: 0 5% 5px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media all and (max-width: 550px) {
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 40px;
      }

      ul {
        list-style-type: none;
        font-size: 30px;
        padding: 0;
        margin: 0;
        @media all and (max-width: 450px) {
          font-size: 25px;
        }

        a {
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          &:hover {
            color: #389e8c;
          }
        }
      }
    }

    p.support-text {
      text-transform: uppercase;
      margin-bottom: 20px;
      font-family: "Times New Roman";
      font-weight: bold;
      color: #000;
    }

    .logos {
      display: flex;
      /*flex-direction: column;*/
      flex-wrap: wrap;
      max-width: 100%;
      @media all and (max-width: 550px) {
        max-width: 100%;
      }
      a {
        display: block;
        margin: 0;
        padding: 0;
      }
      img {
        max-width: 200px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
